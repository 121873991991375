<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar style="position: sticky; top: 0" dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("settings.title") }}</v-toolbar-title>
      </v-toolbar>
      <div class="d-flex flex-column">
        <div style="font-size: 1.1rem; color: #848484" class="bottom-line mt-2 px-3 py-2">
          {{ $t("settings.account") }}
        </div>
        <div class="bottom-line p-3 d-flex">
          <div>{{ $t("settings.email") }}</div>
          <div style="color: #6e6e6e" class="ml-auto">
            {{ userDataGetter.user.email }}
          </div>
        </div>
        <div class="bottom-line p-3 d-flex">
          <div>{{ $t("settings.firstname") }}</div>
          <div style="color: #6e6e6e" class="ml-auto">
            {{ userDataGetter.user.first_name }}
          </div>
        </div>
        <div class="bottom-line p-3 d-flex">
          <div>{{ $t("settings.lastname") }}</div>
          <div style="color: #6e6e6e" class="ml-auto">
            {{ userDataGetter.user.last_name }}
          </div>
        </div>
        <!--  <div @click="dialog.changePassword = true" class="bottom-line p-3 d-flex">
          <div>{{ $t("settings.password") }}</div>
          <img
            style="color: #6e6e6e; height: 1.5rem"
            class="ml-auto my-auto"
            :src="iconLeftArrow"
          />
        </div> -->
        <!--       <div class="bottom-line p-3 d-flex">
          <div>{{ $t("settings.passport") }}</div>
          <div v-if="userDataGetter.havePassport" style="color: #6e6e6e" class="ml-auto">
            {{ $t("settings.verified") }}
          </div>
          <div
            @click="dialog.uploadPassport = true"
            class="d-flex ml-auto"
            style="color: #6e6e6e"
            v-else
          >
            <div>{{ $t("settings.clickHereToUpload") }}</div>
          </div>
        </div> -->
        <div style="font-size: 1.1rem; color: #848484" class="bottom-line mt-6 px-3 py-2">
          {{ $t("settings.general") }}
        </div>
        <div @click="languageButton" class="bottom-line p-3 d-flex">
          <div>{{ $t("settings.language") }}</div>
          <img
            style="color: #6e6e6e; height: 1.5rem"
            class="ml-auto my-auto"
            :src="iconLeftArrow"
          />
        </div>

        <div
          class="text-center mt-12 p-2 w-full"
          style="
            color: red;
            border-bottom: 1px solid #d8d8d8;
            border-top: 1px solid #d8d8d8;
          "
          @click="logout"
        >
          {{ $t("settings.logout") }}
        </div>
      </div>
    </v-card>
    <v-overlay :dark="false" :value="selectLang">
      <v-card class="px-2">
        <v-card-title>{{ $t("login.selectLang") }}</v-card-title>
        <v-card-text>
          <div class="d-flex flex-column">
            <div
              @click="changeLanguage(item.key)"
              v-for="(item, index) in language"
              :key="index"
              style="font-size: 1.2rem"
              class="my-1"
            >
              {{ item.name }}
            </div>
          </div></v-card-text
        >
      </v-card>
    </v-overlay>

    <v-overlay v-if="dialog.changePassword" class="w-full" :dark="false">
      <v-card class="mx-auto" style="width: 90%">
        <v-card-title
          ><div class="d-flex w-full">
            <div>{{ $t("settings.changePassword") }}</div>
            <img
              @click="dialog.changePassword = false"
              :src="closeIcon"
              style="width: 1.5rem"
              class="my-auto ml-auto"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <input
            type="password"
            :placeholder="$t('settings.oldPassword')"
            v-model="oldPassword"
            class="textfield-input w-full"
            autocapitalize="off"
          />
          <input
            type="password"
            :placeholder="$t('settings.newPassword')"
            v-model="newPassword"
            class="textfield-input w-full"
            autocapitalize="off"
          />
          <input
            type="password"
            :placeholder="$t('settings.confirmPassword')"
            v-model="confirmPassword"
            class="textfield-input w-full"
            autocapitalize="off"
          />
          <button @click="submitChangePassword" class="button-submit w-full mt-4">
            {{ $t("settings.submit") }}
          </button>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay v-if="dialog.uploadPassport" class="w-full" :dark="false">
      <v-card class="mx-auto" style="min-width: 100%">
        <v-card-title
          ><div class="d-flex w-full">
            <div>{{ $t("settings.uploadPassport") }}</div>
            <img
              @click="dialog.uploadPassport = false"
              :src="closeIcon"
              style="width: 1.5rem"
              class="my-auto ml-auto"
            />
          </div>
        </v-card-title>
        <v-card-text>
          <input
            :placeholder="$t('settings.passportNumber')"
            v-model="passport"
            class="textfield-input w-full"
            autocapitalize="off"
          />
          <div class="mt-4 w-full">
            <div>{{ $t("register.passportUpload") }}</div>
            <input
              @input="handleFileInput"
              id="passportImage"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              placeholder="Passport"
            />
            <label for="passportImage" class="mt-1 d-flex upload-container">
              <img
                v-if="passportImage == ''"
                class="mx-auto my-auto"
                :src="plusBoxIcon"
              />
              <img
                v-else
                style="object-fit: contain"
                class="h-full mx-auto my-auto"
                :src="passportImage"
              />
            </label>
          </div>
          <button @click="submitPassport" class="button-submit w-full mt-4">
            {{ $t("settings.submit") }}
          </button>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: {
        changePassword: false,
        uploadPassport: false,
      },
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      passport: "",
      passportImage: "",
      selectLang: false,
      isLoading: false,
      closeIcon: require("../../assets/icon/close.png"),
      iconLeftArrow: require("../../assets/icon/chevron-right.png"),
      plusBoxIcon: require("../../assets/icon/plus-box-outline.png"),
      language: [
        { name: "English", key: "en" },
        { name: "繁體中文", key: "zh" },
        { name: "简体中文", key: "cn" },
      ],
    };
  },
  props: ["value"],
  components: {},
  computed: { ...mapGetters(["userDataGetter"]) },
  methods: {
    ...mapMutations(["snackbarSetter", "authSetter", "homeIndexSetter"]),
    logout() {
      this.authSetter({ isLoggedIn: false, token: "" });
      this.homeIndexSetter(0);
      this.$emit("input");
      this.snackbarSetter({ visible: true, message: "Logged out", success: false });
    },
    submitChangePassword() {
      if (this.oldPassword == "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("settings.emptyOldPassword"),
          visible: true,
        });
      } else if (this.newPassword == "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("settings.emptyNewPassword"),
          visible: true,
        });
      } else if (this.confirmPassword == "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("settings.emptyConfirmPassword"),
          visible: true,
        });
      } else if (this.newPassword != this.confirmPassword) {
        this.snackbarSetter({
          success: false,
          message: this.$t("settings.notSame"),
          visible: true,
        });
      } else {
        this.isLoading = true;
        axios({
          method: "POST",
          url: process.env.VUE_APP_BACKENDAPI + "auth/changePassword",
          data: {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
        }).then(
          (response) => {
            this.isLoading = false;

            if (response.data.status) {
              this.snackbarSetter({
                success: true,
                message: this.$t("settings.successContent"),
                visible: true,
              });
              this.dialog.changePassword = false;
              this.oldPassword = "";
              this.newPassword = "";
              this.confirmPassword = "";
            } else {
              this.snackbarSetter({
                success: false,
                message: this.$t("settings.failedContent"),
                visible: true,
              });
            }
          },
          (error) => {
            this.isLoading = false;
            console.log(error);
          }
        );
      }
    },
    submitPassport() {
      if (this.passport == "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("settings.emptyPassport"),
          visible: true,
        });
      } else if (this.passportImage == "") {
        this.snackbarSetter({
          success: false,
          message: this.$t("settings.emptyImage"),
          visible: true,
        });
      } else {
        this.isLoading = true;

        axios({
          method: "POST",
          url: process.env.VUE_APP_BACKENDAPI + "user/uploadPassport",
          data: {
            passport: this.passport,
            image: this.passportImage,
          },
        }).then(
          (response) => {
            this.isLoading = false;
            if (response.data.status) {
              this.snackbarSetter({
                success: true,
                message: this.$t("common.success"),
                visible: true,
              });
              this.dialog.uploadPassport = false;
            } else {
              this.snackbarSetter({
                success: true,
                message: this.$t("common.fail"),
                visible: true,
              });
            }
          },
          (error) => {
            console.log(error);
            this.isLoading = false;
          }
        );
      }
    },
    languageButton() {
      this.selectLang = true;
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      this.selectLang = false;
    },
    handleFileInput(e) {
      if (e.target.files && e.target.files[0]) {
        let img = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataURI = e.target.result;
          this.passportImage = dataURI;
        };
        reader.readAsDataURL(img);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.container-deposit {
  touch-action: pan-y !important;
}
.container-deposit div {
  touch-action: pan-y !important;
}
.bottom-line {
  border-bottom: 1px solid #d8d8d8;
}
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.6rem;
  font-size: 1.2rem;
}
.button-submit {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1.2rem;
}
input[type="file"] {
  display: none;
}
</style>
