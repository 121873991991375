<template>
  <v-dialog
    fullscreen
    persistent
    style="background-color: white"
    @input="$emit('input')"
    :value="value"
  >
    <v-card>
      <v-toolbar dark color="#3d406c">
        <v-btn icon @click="$emit('input')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("register.title") }}</v-toolbar-title>
      </v-toolbar>

      <div
        v-if="tab == 0"
        style="background-color: white"
        class="d-flex flex-column px-8 mt-4"
      >
        <img class="w-28" :src="logo" />
        <div class="my-2" style="font-size: 1.3rem">{{ $t("register.subtitle") }}</div>
        <input
          v-model="email"
          type="email"
          :placeholder="$t('register.email')"
          class="mt-4 textfield-input"
        />
        <input
          v-model="username"
          type="text"
          :placeholder="$t('register.username')"
          class="mt-4 textfield-input"
          autocapitalize="off"
        />
        <input
          v-model="password"
          type="password"
          :placeholder="$t('register.password')"
          class="mt-4 textfield-input"
          autocapitalize="off"
        />
        <input
          v-model="confirmPassword"
          type="password"
          :placeholder="$t('register.confirmPassword')"
          class="mt-4 textfield-input"
          autocapitalize="off"
        />
        <div>{{ $t("register.moreThan8Hints") }}</div>
        <v-alert
          class="my-2"
          dense
          v-if="errorMessage !== ''"
          elevation="3"
          type="error"
          >{{ errorMessage }}</v-alert
        >
        <button class="button-login my-2" @click="nextButton">
          {{ $t("register.next") }}
        </button>
      </div>
      <div
        v-if="tab == 1"
        style="background-color: white"
        class="d-flex flex-column px-8 mt-4"
      >
        <img @click="tab = 0" :src="leftArrowIcon" class="w-8" />
        <input
          type="text"
          :placeholder="$t('register.referralCode')"
          class="mt-4 w-full textfield-input"
          v-model="referralCode"
          autocapitalize="off"
        />
        <v-row no-gutters justify="center" class="mt-4">
          <v-col cols="6">
            <div class="d-flex">
              <input
                type="text"
                style="width: 95%"
                :placeholder="$t('register.firstName')"
                class="textfield-input mr-auto"
                v-model="firstName"
                autocapitalize="off"
              />
            </div>
          </v-col>
          <v-col cols="6">
            <div class="d-flex">
              <input
                type="text"
                style="width: 95%"
                :placeholder="$t('register.lastName')"
                class="textfield-input ml-auto"
                v-model="lastName"
                autocapitalize="off"
              />
            </div>
          </v-col>
        </v-row>

        <div class="d-flex mt-4" @click="passportCheck">
          <img
            class="w-6 my-auto"
            :src="submitPassportLater ? checkBoxMarkedIcon : checkBoxBlankIcon"
          />
          <div class="ml-2 my-auto">{{ $t("register.submitLater") }}</div>
        </div>
        <div v-if="!submitPassportLater">
          <input
            type="text"
            :placeholder="$t('register.passport')"
            class="mt-4 w-full textfield-input"
            v-model="passportNumber"
            autocapitalize="off"
          />

          <div class="mt-4">
            <div>{{ $t("register.passportUpload") }}</div>
            <input
              @input="handleFileInput"
              id="passportImage"
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              placeholder="Passport"
            />
            <label for="passportImage" class="mt-1 d-flex upload-container">
              <img
                v-if="passportImage == ''"
                class="mx-auto my-auto"
                :src="plusBoxIcon"
              />
              <img
                v-else
                style="object-fit: contain"
                class="h-full mx-auto my-auto"
                :src="passportImage"
              />
            </label>
          </div>
        </div>
        <v-alert
          class="my-2"
          dense
          v-if="errorMessage2 !== ''"
          elevation="3"
          type="error"
          >{{ errorMessage2 }}</v-alert
        >
        <div>
          {{ $t("register.agree1")
          }}<a href="https://www.bitchaintify.com/terms">{{ $t("register.agree2") }}</a>
          {{ $t("register.agree3")
          }}<a href="https://www.bitchaintify.com/privacy">{{ $t("register.agree4") }}</a>
        </div>
        <button @click="registerButton" class="button-login mt-4">
          {{ $t("register.register") }}
        </button>
      </div>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular
        color="blue darken-4"
        indeterminate
        size="40"
      ></v-progress-circular>
    </v-overlay>
    <v-overlay :value="success">
      <v-card color="white">
        <v-card-text>
          <div style="color: black" class="py-2 px-4 d-flex flex-column">
            <div class="text-3xl mx-auto">{{ $t("register.success") }}</div>
            <img class="mx-auto my-2 w-24" :src="successImage" />
            <div class="text-sm mx-auto">{{ $t("register.success1") }}</div>
            <div class="text-sm mx-auto">{{ $t("register.success2") }}</div>
            <v-btn @click="$emit('input')" class="mt-4" color="blue-grey darken-4">{{
              $t("common.ok")
            }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      passportNumber: "",
      passportImage: "",
      referralCode: "",
      tab: 0,
      submitPassportLater: false,
      isLoading: false,
      success: false,
      errorMessage: "",
      errorMessage2: "",
      logo: require("../../assets/logo/logo-1.png"),
      leftArrowIcon: require("../../assets/icon/arrow-left.png"),
      checkBoxBlankIcon: require("../../assets/icon/checkbox-blank-circle-outline.png"),
      checkBoxMarkedIcon: require("../../assets/icon/checkbox-marked-circle-outline.png"),
      plusBoxIcon: require("../../assets/icon/plus-box-outline.png"),
      successImage: require("../../assets/check.png"),
    };
  },
  props: ["value"],
  components: {},
  computed: {},
  methods: {
    passportCheck() {
      this.submitPassportLater = !this.submitPassportLater;
      if (this.submitPassportLater) {
        alert(this.$t("register.passportReminder"));
      }
    },
    nextButton() {
      if (!/.+@.+\..+/.test(this.email)) {
        this.errorMessage = this.$t("register.wrongEmail");
      } else if (this.username.length < 5) {
        this.errorMessage = this.$t("register.tooShortUsername");
      } else if (this.password.length < 8) {
        this.errorMessage = this.$t("register.tooShortPassword");
      } else if (this.password != this.confirmPassword) {
        this.errorMessage = this.$t("register.passwordNotMatch");
      } else {
        this.tab = 1;
      }
    },
    registerButton() {
      if (this.firstName === "") {
        this.errorMessage2 = this.$t("register.emptyFisrtName");
      } else if (this.lastName === "") {
        this.errorMessage2 = this.$t("register.emptyLastName");
      } else if (this.passport === "" && !this.submitPassportLater) {
        this.errorMessage2 = this.$t("register.emptyPassport");
      } else if (this.passportImage === "" && !this.submitPassportLater) {
        this.errorMessage2 = this.$t("register.emptyPassportImg");
      } else {
        this.isLoading = true;
        axios({
          method: "POST",
          url: process.env.VUE_APP_BACKENDAPI + "auth/userRegister",
          data: {
            Username: this.username,
            Email: this.email,
            Password: this.password,
            FirstName: this.firstName,
            LastName: this.lastName,
            Passport: this.submitPassportLater ? "" : this.passportNumber,
            PassportImage: this.submitPassportLater ? "" : this.passportImage,
            ReferralCode: this.referralCode,
          },
        }).then(
          (response) => {
            this.isLoading = false;
            if (response.data.status) {
              this.success = true;
            } else {
              this.errorMessage2 = this.$t(`register.${response.data.message}`);
            }
          },
          (error) => {
            this.isLoading = false;

            console.log(error);
          }
        );
      }
    },
    handleFileInput(e) {
      if (e.target.files && e.target.files[0]) {
        let img = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const dataURI = e.target.result;
          this.passportImage = dataURI;
        };
        reader.readAsDataURL(img);
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.textfield-input {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;

  padding: 0.6rem;
  font-size: 1.2rem;
}
.upload-container {
  color: #000000;
  border-style: solid;
  border-width: 1px;
  border-color: #8c8c8c;
  border-radius: 0.7rem;
  height: 10rem;
  padding: 0.6rem;
  font-size: 1.2rem;
}
.button-login {
  font-size: 1.2rem;
  background-color: #3d406c;
  color: #ffffff;
  padding: 0.4rem;
  border-radius: 0.75rem;
}
input[type="file"] {
  display: none;
}
</style>
